import React, { PropsWithChildren } from 'react'

import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { getCurrentUrl } from '../../store/ui/reducers'
import { RootState } from '../../index'
import { InjectedIntlProps } from '../shared/types/conponents-base.types'

const mapStateToProps = (state: RootState) => {
  return { currentUrl: getCurrentUrl(state) };
};

type Props = PropsWithChildren<{
    routeId: string;
    params?: any;
    className?: string;
    queryString?: string;
    target?: string;
    onClick?: any;
    enableActiveClass?: boolean;
}> & ReturnType<typeof mapStateToProps> & InjectedIntlProps

const LocalizedLink: React.FC<Props> = ({
  currentUrl,
  intl,
  routeId,
  params = {},
  children,
  className,
  queryString,
  target,
  onClick,
  enableActiveClass = true
}) => {
  const route = Object.keys(params).reduce(function (previous, value) {
    let optional = previous.replace(":" + value + "?", params[value]);
    return optional.replace(":" + value, params[value]);
  }, intl.formatMessage({ id: routeId }));
  let url = route;
  let active = url === currentUrl.pathname;
  if (queryString !== undefined) {
    url = route + queryString;
    // active = active && currentUrl.search.startsWith(queryString) && currentUrl.search !== ''
    active =
      active && currentUrl.search === queryString && currentUrl.search !== "";
  } else {
    active = active && currentUrl.search === "";
  }
  // Quitar barra (/) final
  url = url.replace(new RegExp("[/]+$"), "");
  return (
    <Link
      to={url}
      className={`${className} ${active && enableActiveClass ? " active" : ""}`}
      target={target}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default injectIntl(connect(mapStateToProps, null)(LocalizedLink));
