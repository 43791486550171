import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/global/Button'
import Translation from '../../components/global/Translation'
import Modal from '../../components/global/Modal'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { addItemsPriceList } from '../../store/prices-list/actions'
import { getPricesList } from '../../store/prices-list/reducers'
import { AppDispatch, RootState } from '../../index'
import Select from 'react-select/dist/react-select.esm'
import Alerts from '../../components/alerts/Alerts'

defineMessages(
    {
        PRICE_LIST_ADD_INFO_OF_MOVE_SELECTED_ITEMS: {
            id: 'PRICE_LIST_ADD_INFO_OF_MOVE_SELECTED_ITEMS',
            description: 'PRICE_LIST_ADD_INFO_OF_MOVE_SELECTED_ITEMS',
            defaultMessage: 'The selected sale items already belong to these prices list, if you continue, they will be moved into selected price list',
        }
    }
)

interface Props {
    saleItemIdsSelected: number[];
    saleItemsSelected: any[];
    onClose: () => void;
    onCancel: () => void;
    onSaved: () => void;
}

const PricesListAddOrMoveItems: React.FC<Props> = ({ saleItemIdsSelected, saleItemsSelected, onClose, onCancel, onSaved, }) => {
    const intl = useIntl()
    const ref = useRef<HTMLDivElement>(null);

    const [distinctPricesListSaleItemsSelected, setDistinctPricesListSaleItemsSelected] = useState<string[]>([])
    const [priceListIdSelected, setPriceListIdSelected] = React.useState<number | null>(null)

    useEffect(() => {
        const distinctPriceListSaleItemsSelected = saleItemsSelected.map(
            (saleItem) => saleItem.price_list_name).filter(
                (value) => !!value
        )
        setDistinctPricesListSaleItemsSelected(distinctPriceListSaleItemsSelected)
    }, [])

    const priceListsOptions = useSelector((state: RootState) => getPricesList(state)).map((priceList) => ({
        value: priceList.id,
        label: priceList.name
    }))

    const dispatch = useDispatch<AppDispatch>()
    const addItems = (): void => {
        dispatch(addItemsPriceList({ id: priceListIdSelected, saleItems: saleItemIdsSelected })).then(() => {
            onClose()
            onSaved()
        })
    }

    return <Modal
        ref={ref}
        title={`Do you want to add ${saleItemsSelected.length} items to price list selected?`}
        size='large'
        onClose={onClose}
        footer={(
            <div className='d-flex justify-content-end gap-2'>
                <Button onClick={onCancel} secondary datacy='confirm_dialog__cancel'>
                    CANCEL
                </Button>

                <Button disabled={!priceListIdSelected} onClick={addItems} secondary datacy='confirm_dialog__ok'>
                    ADD
                </Button>
            </div>
        )}
    >
        <div className='container-fluid' style={{minHeight: '350px'}}>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-check'>
                        <label htmlFor='priceList'
                               className='form-check-label'>
                            <Translation id='PRICE_LIST_SELECT_LABEL' defaultMessage='Price list for adding items' />
                        </label>

                        <Select
                            id='priceList'
                            name='priceList'
                            maxMenuHeight={225}
                            autoFocus
                            menuPlacement="auto"
                            menuPortalTarget={ref.current}
                            options={priceListsOptions}
                            value={priceListsOptions.find((priceList) => priceList.value === priceListIdSelected)}
                            onChange={option => setPriceListIdSelected(option.value ?? null)}
                        />
                    </div>
                </div>

                {distinctPricesListSaleItemsSelected.length > 0 && (
                    <div className='col-12'>
                        <div className='form-check'>

                            <Alerts
                                status='info'
                                alert='PRICE_LIST_ADD_INFO_OF_MOVE_SELECTED_ITEMS'
                            ></Alerts>

                            <div>
                                {
                                    distinctPricesListSaleItemsSelected.map((priceListName) => (
                                        <span key={priceListName} className="badge" style={{background: 'blue', marginLeft: '16px', fontSize: '11px'}}>
                                            {priceListName}
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )}


            </div>
        </div>
    </Modal>
}

export default PricesListAddOrMoveItems