import { connect, ConnectedProps } from 'react-redux'
import { AppDispatch, RootState } from '../../index'
import { fetchMyPricesList } from '../../store/prices-list/actions'
import { getPricesList } from '../../store/prices-list/reducers'
import PricesList from './PricesList'

const mapStateToProps = (state: RootState) => {
    return {
        pricesList: getPricesList(state)
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        fetchMyPricesList: () => {
            return dispatch(fetchMyPricesList())
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export type PriceListContainerReduxProps = ConnectedProps<typeof connector>

const PricesListContainer = connector(PricesList)

export default PricesListContainer
