import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter,Route,Switch } from 'react-router-dom'
import AddToCollectionScreen from '../screens/AddToCollectionScreen'
import CreditHistoryScreen from '../screens/CreditHistoryScreen'
import CollectionScreen from '../screens/CollectionScreen'
import EditItemFromCollectionScreen from './EditItemFromCollectionScreen';
import SaleItemsScreen from './SaleItemsScreen';
import BuyItemsScreen from './BuyItemsScreen'
import AddToSaleScreen from './AddToSaleScreen';
import AddToBuyScreen from './AddToBuyScreen'
import AddBuyCreditScreen from './SetBuyCreditScreen'
import AddCreditByMobileScreen from './AddCreditByMobileScreen'
import AddCreditByBankScreen from './AddCreditByBankScreen'
import AddCreditScreen from './AddCreditScreen'
import RetrieveCreditScreen from './RetrieveCreditScreen'
import EditItemFromSaleScreen from './EditItemFromSaleScreen';
import EditItemFromBuyScreen from './EditItemFromBuyScreen'
import ChatScreen from './ChatScreen'
import SalesScreen from './SalesScreen'
import BuysScreen from './BuysScreen'
import DefaultDashboardScreen from './DefaultDashboardScreen'
import TransactionsScreen from './TransactionsScreen'
import PrivateRoute from '../components/routes/PrivateRoute'
import AddCreditByGatewayScreen from './AddCreditByGatewayScreen'
import PricesListScreen from '../modules/PricesList/PricesListScreen'
import BusinessRoute from '../components/routes/BusinessRoute'

const DashboardScreen = (props) => {
    return (
        <div>
            <div>
                <Switch>
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'collection')} component={CollectionScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'add-to-collection')} component={AddToCollectionScreen} exact />
                    <PrivateRoute
                        path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'edit-item-collection')}
                        component={EditItemFromCollectionScreen}
                        exact
                    />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'transactions')} component={TransactionsScreen} exact />

                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'sales-list')} component={SalesScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'buys-list')} component={BuysScreen} exact />
                    <Route path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'sale')} component={SaleItemsScreen} exact />
                    <Route path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'buy')} component={BuyItemsScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'add-to-sale')} component={AddToSaleScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'add-to-buy')} component={AddToBuyScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'edit-item-sale')} component={EditItemFromSaleScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'edit-item-buy')} component={EditItemFromBuyScreen} exact />

                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'chats/:activeChatId?')} component={ChatScreen} exact />

                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'add-buy-credit')} component={AddBuyCreditScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'add-credit')} component={AddCreditScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'retrieve-credit')} component={RetrieveCreditScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'credit-history')} component={CreditHistoryScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'add-credit-by-payment-gateway')} component={AddCreditByGatewayScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'add-credit-by-mobile')} component={AddCreditByMobileScreen} exact />
                    <PrivateRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'add-credit-by-bank')} component={AddCreditByBankScreen} exact />

                    <BusinessRoute path={props.intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' }).replace(':view?', 'prices-list')} component={PricesListScreen} exact />

                    <PrivateRoute component={DefaultDashboardScreen} />
                </Switch>
            </div>
        </div>
    )
}

export default withRouter(injectIntl(connect()(DashboardScreen)))
