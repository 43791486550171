import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter,Route,Switch } from 'react-router-dom'
import AddToSaleForm from '../modules/WantToSell/AddToSaleForm'

const EditItemFromSaleScreen = (props,intl) => {
    return (
        <div className="container-fluid">
        {/* <h2>add to collection</h2> */}
            <AddToSaleForm mode={'edit'}></AddToSaleForm>
        </div>
    )
}

export default withRouter(injectIntl(connect()(EditItemFromSaleScreen)))
