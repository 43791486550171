import { AppDispatch } from '../../index'
import {
    ADD_ITEMS_PRICE_LIST_FAILURE,
    ADD_ITEMS_PRICE_LIST_REQUEST, ADD_ITEMS_PRICE_LIST_SUCCESS,
    CREATE_PRICES_LIST_FAILED,
    CREATE_PRICES_LIST_REQUEST, CREATE_PRICES_LIST_SUCCESS,
    FETCH_PRICES_LIST_FAILED,
    FETCH_PRICES_LIST_REQUEST,
    FETCH_PRICES_LIST_SUCCESS
} from './reducers'
import { getIsBusiness, getIsLogged, getVerified } from '../login/reducers'
import * as api from '../api'
import { PriceListAddItemsDto, PriceListCreateDto, PriceListDto } from './core/dto/price-list.dto'
import { addSaleItemsToPriceList } from '../api'


export const fetchMyPricesList = () => (dispatch: AppDispatch, getState): Promise<void> => {
    const isVerified = getVerified(getState())
    const isBusiness = getIsBusiness(getState())

    if (!isBusiness || !isVerified) {
        return
    }

    dispatch(FETCH_PRICES_LIST_REQUEST())

    return api.fetchPricesList()
        .then((response) => {
            dispatch(FETCH_PRICES_LIST_SUCCESS({ listPrices: response.data as PriceListDto[] }))
        })
        .catch((error) => {
            dispatch(FETCH_PRICES_LIST_FAILED())
        })
}

export const createPriceList = (data: PriceListCreateDto) => (dispatch: AppDispatch, getState): Promise<void> => {
    const isVerified = getVerified(getState())
    const isBusiness = getIsBusiness(getState())

    if (!isBusiness || !isVerified) {
        return
    }

    dispatch(CREATE_PRICES_LIST_REQUEST())

    return api.createPriceList(data)
        .then((response) => {
            dispatch(CREATE_PRICES_LIST_SUCCESS({ listPrice: response.data as PriceListDto }))
        })
        .catch((error) => {
            const errors = null;

            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors
            }

            dispatch(CREATE_PRICES_LIST_FAILED({
                errors,
                errorCode: error.response.data.err ?? null
            }))
            return Promise.reject()
        })
}

export const addItemsPriceList = (data: PriceListAddItemsDto) => (dispatch: AppDispatch, getState): Promise<void> => {
    const isVerified = getVerified(getState())
    const isBusiness = getIsBusiness(getState())

    if (!isBusiness || !isVerified) {
        return
    }

    dispatch(ADD_ITEMS_PRICE_LIST_REQUEST())

    return api.addSaleItemsToPriceList(data.id, data.saleItems)
        .then((response) => {
            dispatch(ADD_ITEMS_PRICE_LIST_SUCCESS())
        })
        .catch((error) => {
            const errors = null;

            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors
            }

            dispatch(ADD_ITEMS_PRICE_LIST_FAILURE({
                errors,
                errorCode: error.response.data.err ?? null
            }))
            return Promise.reject()
        })
}