import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { InjectedIntlProps } from '../shared/types/conponents-base.types'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'

defineMessages({
    TOKEN_HAS_EXPIRED: {
        id: 'TOKEN_HAS_EXPIRED',
        description: 'TOKEN_HAS_EXPIRED',
        defaultMessage: 'Your session has expired'
    },
    ORDER_BUY_NOT_ENOUGH_CREDIT: {
        id: 'ORDER_BUY_NOT_ENOUGH_CREDIT',
        description: 'ORDER_BUY_NOT_ENOUGH_CREDIT',
        defaultMessage: 'Error in checkout. Buyers haven\'t enough buy credit.'
    },
    IMAGE_FRONT: {
        id: 'IMAGE_FRONT',
        description: 'IMAGE_FRONT',
        defaultMessage: 'Card front photo'
    },
    IMAGE_BACK: {
        id: 'IMAGE_BACK',
        description: 'IMAGE_BACK',
        defaultMessage: 'Card back photo'
    },
    CONFIRM_DELETE_ITEM_FROM_SALE: {
        id: 'CONFIRM_DELETE_ITEM_FROM_SALE',
        description: 'CONFIRM_DELETE_ITEM_FROM_SALE',
        defaultMessage: 'Delete item from sale?'
    },
    CONFIRM_DELETE_ITEM_FROM_BUY: {
        id: 'CONFIRM_DELETE_ITEM_FROM_BUY',
        description: 'CONFIRM_DELETE_ITEM_FROM_BUY',
        defaultMessage: 'Delete item from buy?'
    },
    CONFIRM_DELETE_ITEM_FROM_COLLECTION: {
        id: 'CONFIRM_DELETE_ITEM_FROM_COLLECTION',
        description: 'CONFIRM_DELETE_ITEM_FROM_COLLECTION',
        defaultMessage: 'Delete item from collection?'
    },
    FORM_ERROR_MIN: {
        id: 'FORM_ERROR_MIN',
        description: 'FORM_ERROR_MIN',
        defaultMessage: 'Min value is {min}'
    },
    FORM_ERROR_MAX: {
        id: 'FORM_ERROR_MAX',
        description: 'FORM_ERROR_MAX',
        defaultMessage: 'Max value is {max}'
    },
    PARTIAL_REFUND_SUCCESS: {
        id: 'PARTIAL_REFUND_SUCCESS',
        description: 'PARTIAL_REFUND_SUCCESS',
        defaultMessage: 'Partial refund successful'
    },
    INVALID_PARTIAL_REFUND_AMOUNT: {
        id: 'INVALID_PARTIAL_REFUND_AMOUNT',
        description: 'INVALID_PARTIAL_REFUND_AMOUNT',
        defaultMessage: 'Invalid partial refund amount'
    }
})

type Props = {
    id: string
    defaultMessage?: string
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
}

type State = {
    showInsertTranslation: boolean
}

class Translation extends React.Component<Props & InjectedIntlProps, State> {
    state = { showInsertTranslation: false }

    render() {
        return (
            <div style={{ all: 'unset' }}>
                {this.props.intl.formatMessage({
                    id: this.props.id,
                    defaultMessage: this.props.defaultMessage
                }, this.props.values ? this.props.values : {})}
            </div>
        )
    }
}

export default injectIntl(Translation)
