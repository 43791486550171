import React from 'react'
import { useField } from 'formik'

interface InputFieldProps {
    name: string;
    label?: string;
    type?: string;
    required?: boolean;
    autoComplete?: string;
    style?: React.CSSProperties;
}

const FormikInput: React.FC<InputFieldProps> = ({
                                                   name,
                                                   label,
                                                   type = 'text',
                                                   required = false,
                                                   autoComplete = 'off',
                                                   style,
                                               }) => {
    const [field, meta] = useField(name);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
            {label && <label htmlFor={name}>{label}</label>}
            <input
                {...field}
                id={name}
                type={type}
                required={required}
                autoComplete={autoComplete}
            />
            {meta.touched && meta.error && (
                <div style={{ color: 'red' }}>{meta.error}</div>
            )}
        </div>
    );
};


export default FormikInput;