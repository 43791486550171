import React from 'react'
import { withRouter } from 'react-router-dom'
import { PropsWithRouter } from '../../components/shared/types/conponents-base.types'
import PricesListContainer from './PriceListContainer'


const PricesListScreen: React.FC<PropsWithRouter<{}>> = () => {
    return (
        <PricesListContainer />
    )
}

export default withRouter(PricesListScreen)
