import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
    onClick?: () => void,
    inverted?: boolean,
    secondary?: boolean,
    gentle?: boolean,
    danger?: boolean,
    inline?: boolean,
    noborder?: boolean,
    size?: 'small' | 'medium' | 'large',
    customClass?: string,
    disabled?: boolean,
    hidden?: boolean,
    icon?: string,
    title?: string,
    far?: boolean,
    iconPosition?: 'start' | 'end',
    datacy?: string,
    className?: string
    type?: 'button' | 'submit' | 'reset'
}>

const Button: React.FC<Props> = props => {
    let onClick = props.onClick
    let cssClasses = 'bt noselect'
    let title
    let datacy = props.datacy
    if (props.inverted) {
        cssClasses += ' bt-inverted'
    }
    if (props.secondary) {
        cssClasses += ' bt-secondary'
    }
    if (props.gentle) {
        cssClasses += ' bt-gentle'
    }
    if (props.danger) {
        cssClasses += ' bt-danger'
    }
    if (props.inline) {
        cssClasses += ' bt-inline'
    }
    if (props.noborder) {
        cssClasses += ' bt-noborder'
    }
    if (props.size) {
        cssClasses += ' bt-size-' + props.size
    }
    if (props.customClass) {
        cssClasses += ' ' + props.customClass
    }
    if (props.disabled) {
        cssClasses += ' bt-disabled'
        onClick = null
    }
    if (props.hidden) {
        return <div style={{ display: 'none' }} />
    }
    let iconCssClasses
    if (props.far) {
        iconCssClasses = 'icon far'
    } else {
        iconCssClasses = 'icon fas'
    }
    if (props.icon) {
        if (props.icon === 'custom') {
            cssClasses += ' has-custom-icon'
        } else {
            iconCssClasses += ' visible fa-' + props.icon
        }
    }
    if (props.title) {
        title = props.title
    }

    let iconPosition = props.iconPosition ? props.iconPosition : 'start'

    cssClasses = props.className ? cssClasses += ' ' + props.className : cssClasses

    return (
        <div className={cssClasses} onClick={onClick} title={title} data-cy={datacy}>
            {iconPosition === 'start' ? <i className={iconCssClasses} /> : ''}
            {props.children}
            {iconPosition === 'end' ? <i className={iconCssClasses} /> : ''}
        </div>
    )
}

export default Button
