import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PriceListDto } from './core/dto/price-list.dto'
import { RootState } from '../../index'
import { ErrorsType } from '../../components/shared/types/api.types'

interface PricesListState {
    pricesList: PriceListDto[],
    isLoading: boolean,
    errors?: ErrorsType | null,
}

const initialState: PricesListState = {
    pricesList: [],
    isLoading: false,
    errors: null,
}

const pricesListSlice = createSlice({
    name: 'pricesList',
    initialState,
    reducers: {
        FETCH_PRICES_LIST_REQUEST: (state, action: PayloadAction) => {
            state.pricesList = initialState.pricesList
            state.isLoading = true
        },
        FETCH_PRICES_LIST_SUCCESS: (state, action: PayloadAction<{ listPrices: PriceListDto[] }>) => {
            state.pricesList = action.payload.listPrices
            state.isLoading = false
        },
        FETCH_PRICES_LIST_FAILED: (state, action: PayloadAction) => {
            state.isLoading = false
        },
        CREATE_PRICES_LIST_REQUEST: (state, action: PayloadAction) => {
            state.isLoading = true
        },
        CREATE_PRICES_LIST_SUCCESS: (state, action: PayloadAction<{ listPrice: PriceListDto }>) => {
            state.pricesList.push(action.payload.listPrice)
            state.isLoading = false
        },
        CREATE_PRICES_LIST_FAILED: (state, action: PayloadAction<{ errors?: ErrorsType, errorCode?: string}>) => {
            state.isLoading = false
            state.errors = action.payload.errors
        },
        ADD_ITEMS_PRICE_LIST_REQUEST: (state, action: PayloadAction) => {
            state.isLoading = true
        },
        ADD_ITEMS_PRICE_LIST_SUCCESS: (state, action: PayloadAction) => {
            state.isLoading = false
        },
        ADD_ITEMS_PRICE_LIST_FAILURE: (state, action: PayloadAction<{ errors?: ErrorsType, errorCode?: string}>) => {
            state.isLoading = false
            state.errors = action.payload.errors
        },
    }
})

export const {
    FETCH_PRICES_LIST_REQUEST,
    FETCH_PRICES_LIST_SUCCESS,
    FETCH_PRICES_LIST_FAILED,
    CREATE_PRICES_LIST_REQUEST,
    CREATE_PRICES_LIST_SUCCESS,
    CREATE_PRICES_LIST_FAILED,
    ADD_ITEMS_PRICE_LIST_REQUEST,
    ADD_ITEMS_PRICE_LIST_SUCCESS,
    ADD_ITEMS_PRICE_LIST_FAILURE,
} = pricesListSlice.actions

export const getPricesList = (state: RootState): PriceListDto[] => state.pricesList.pricesList

export const getPriceListFilterOptions = (state: RootState) => [
    { value: null, label: 'Any' },
    { value: 'EMPTY', label: '-Not in a List-'},
    ...getPricesList(state).map((priceList: PriceListDto) => ({ value: priceList.id, label: priceList.name }))
]


export const getPricesListIsLoading = (state: RootState): boolean => state.pricesList.isLoading

export const getPricesListFormErrors = (state: RootState): ErrorsType | null  => state.pricesList.errors

export default pricesListSlice.reducer