import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import LocalizedLink from '../../global/LocalizedLink'
import { RenderNotification } from '../notification/RenderNotification'

const Header = (props) => {
    return <header className='container-fluid'>
        <div className='row'>
            <div className='col-9 my-auto'>
                <div className='go-back' onClick={props.onClick} data-cy='mini-notifications__back'>
                    <i className={'fal fa-chevron-left'} />
                    <span className='label'>
                    <Translation id='back' defaultMessage='Back' />
                </span>
                </div>
            </div>
            <div className='col-3 my-auto text-right'>
                <div className='bt-close' onClick={props.onClick} data-cy='mini-notifications__close'>
                    <i className='fal fa-times' />
                </div>
            </div>
        </div>
    </header>
}


const PanelMiniNotifications = ({ notifications, qtyPendingTasks }) => {

    if (notifications.length + qtyPendingTasks <= 0) {
        return <div className='container-fluid'>
            <div className='card'>
                <div className='card-body'>
                    <Translation id='all_your_notifications_has_been_read'
                                 defaultMessage='All your notifications has been read ' />
                </div>
            </div>
        </div>
    }

    return <div data-cy='mini-notifications__list' style={{ display: 'flex', flexDirection: 'column', gap: '8px', margin: '0 8px' }}>

        {notifications.length > 0 && notifications.map(
            (notificationIt) => <RenderNotification key={notificationIt.id} notification={notificationIt} />
        )}

        {qtyPendingTasks > 0 &&
            <RenderNotification notification={{
                'notification_type': 'HAS_PENDING_TASKS',
                'qtyPendingTask': qtyPendingTasks
            }} />
        }

        <div className='card bg-color-primary'>
            <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                           params={{ view: '', id: '' }}>
                <div className='card-body text-center'>

                    <Translation id='view_all_notifications'
                                 defaultMessage='View all notifications' />

                </div>
            </LocalizedLink>
        </div>
    </div>
}

const MiniNotifications = (props) => {
    const { show, notifications, qtyPendingTasks, onClose } = props

    return (
        <div>
            <div className='modal-overlay' style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
            <div id='mini-notifications-container' data-cy='mini-notifications-container' className='bg-color-header'
                 style={{ display: show ? 'block' : 'none' }}>
                <div id='mini-notifications'>
                    <Header onClick={onClose} />
                    <PanelMiniNotifications notifications={notifications} qtyPendingTasks={qtyPendingTasks} />
                    <footer className='container-fluid'></footer>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(MiniNotifications)
