import Translation from '../../components/global/Translation'
import Select from 'react-select'
import Button from '../../components/global/Button'
import React, { useState } from 'react'
import Modal from '../../components/global/Modal'
import Filter from '../../components/shared/filters/Filter'
import { defineMessages, injectIntl } from 'react-intl'

defineMessages({
    name: {
        id: 'name',
        description: 'name',
        defaultMessage: 'Name'
    },
    expansion: {
        id: 'expansion',
        description: 'expansion',
        defaultMessage: 'Expansion'
    },
    rarity: {
        id: 'rarity',
        description: 'rarity',
        defaultMessage: 'Rarity'
    },
    PRODUCT_CATEGORY: {
        id: 'PRODUCT_CATEGORY',
        description: 'Product category',
        defaultMessage: 'Product category'
    },
    LANGUAGE: {
        id: 'language',
        description: 'Language',
        defaultMessage: 'Language'
    },
    condition: {
        id: 'condition',
        description: 'Condition',
        defaultMessage: 'Condition'
    },
    price_min: {
        id: 'price_min',
        description: 'Price min',
        defaultMessage: 'Price min'
    },
    price_max: {
        id: 'price_max',
        description: 'Price max',
        defaultMessage: 'Price max'
    },
    foil: {
        id: 'foil',
        description: 'Foil',
        defaultMessage: 'Foil'
    },
    signed: {
        id: 'signed',
        description: 'Signed',
        defaultMessage: 'Signed'
    },
    playset: {
        id: 'playset',
        description: 'Playset',
        defaultMessage: 'Playset'
    },
    altered: {
        id: 'altered',
        description: 'Altered',
        defaultMessage: 'Altered'
    },
})

const Filters = (
    {
        orderBy,
        filters,
        intl,
        expansionOptions,
        rarityOptions,
        productsCategoriesOptions,
        availableLangs,
        availableStatus,
        booleanOptions,
        availableOrderByOptions,
        priceListFilterOptions,
        shouldDisplayProductCategoryFilter,
        shouldDisplayPricesListFilter,
        setFilters,
        setOrderBy,
        onSubmit,
        onReset,
    }
) => {
    return (
        <form className='card-body' onSubmit={onSubmit}>
            <div className='row'>
                <div className='col-12 col-lg-3 col-xl-2'>
                    <Filter
                        type='text'
                        label={intl.formatMessage({ id: 'name' })}
                        name='search'
                        value={filters.search}
                        updateFilterValue={setFilters}
                        placeholder={intl.formatMessage({ id: 'SEARCH_BY_NAME_OR_WHATEVER' })}
                    />
                </div>
                <div className='col-12 col-lg-3 col-xl-2'>
                    <Filter
                        type='select'
                        label={intl.formatMessage({ id: 'expansion' })}
                        name='selectedExpansion'
                        value={filters.selectedExpansion}
                        selectOptions={expansionOptions}
                        updateFilterValue={setFilters}
                    />
                </div>
                <div className='col-12 col-lg-3 col-xl-2'>
                    <Filter
                        type='select'
                        label={intl.formatMessage({ id: 'rarity' })}
                        name='selectedRarity'
                        value={filters.selectedRarity}
                        selectOptions={rarityOptions}
                        updateFilterValue={setFilters}
                    />
                </div>
                {shouldDisplayProductCategoryFilter && (
                    <div className='col-12 col-lg-4 col-xl-2'>
                        <Filter
                            type='select'
                            label={intl.formatMessage({ id: 'PRODUCT_CATEGORY' })}
                            name='productCategory'
                            value={filters.productCategory}
                            selectOptions={productsCategoriesOptions}
                            updateFilterValue={setFilters}
                        />
                    </div>
                )}
                <div className='col-12 col-lg-3 col-xl-2'>
                    <Filter
                        type='select'
                        label={intl.formatMessage({ id: 'language' })}
                        name='selectedLang'
                        value={filters.selectedLang}
                        selectOptions={availableLangs}
                        updateFilterValue={setFilters}
                    />
                </div>
                <div className='col-12 col-lg-3 col-xl-2'>
                    <Filter
                        type='select'
                        label={intl.formatMessage({ id: 'condition' })}
                        name='selectedCondition'
                        value={filters.selectedCondition}
                        selectOptions={availableStatus}
                        updateFilterValue={setFilters}
                    />
                </div>
                <div className='col-12 col-lg-2 col-xl-1'>
                    <Filter
                        type='select'
                        label={intl.formatMessage({ id: 'foil' })}
                        name='isFoil'
                        value={filters.isFoil}
                        selectOptions={booleanOptions}
                        updateFilterValue={setFilters}
                    />
                </div>
                <div className='col-12 col-lg-2 col-xl-1'>
                    <Filter
                        type='select'
                        label={intl.formatMessage({ id: 'signed' })}
                        name='isSigned'
                        value={filters.isSigned}
                        selectOptions={booleanOptions}
                        updateFilterValue={setFilters}
                    />
                </div>
                <div className='col-12 col-lg-2 col-xl-1'>
                    <Filter
                        type='select'
                        label={intl.formatMessage({ id: 'playset' })}
                        name='isPlayset'
                        value={filters.isPlayset}
                        selectOptions={booleanOptions}
                        updateFilterValue={setFilters}
                    />
                </div>
                <div className='col-12 col-lg-2 col-xl-1'>
                    <Filter
                        type='select'
                        label={intl.formatMessage({ id: 'altered' })}
                        name='isAltered'
                        value={filters.isAltered}
                        selectOptions={booleanOptions}
                        updateFilterValue={setFilters}
                    />
                </div>

                {'priceMin' in filters && 'priceMax' in filters && (
                    <div className='col-12 col-lg-3 col-xl-2'>
                        <div className='d-flex flex-row'>
                            <Filter
                                type='number'
                                label={intl.formatMessage({ id: 'price_min' })}
                                name='priceMin'
                                value={filters.priceMin}
                                updateFilterValue={setFilters}
                                min={0.0}
                                step={0.01}
                            />
                            <Filter
                                type='number'
                                label={intl.formatMessage({ id: 'price_max' })}
                                name='priceMax'
                                value={filters.priceMax}
                                updateFilterValue={setFilters}
                                min={0.0}
                                step={0.01}
                            />
                        </div>
                    </div>
                )}

                {shouldDisplayPricesListFilter && ('priceList' in filters) && !!priceListFilterOptions && (
                    <div className='col-12 col-lg-3 col-xl-2'>
                        <div className='d-flex flex-row'>
                            <Filter
                                type='select'
                                label='Price list'
                                name='priceList'
                                value={filters.priceList}
                                selectOptions={priceListFilterOptions}
                                updateFilterValue={setFilters}
                            />
                        </div>
                    </div>
                )}


                <div className='col-12 col-lg-3 col-xl-2'>
                    <SaleItemsOrderBy
                        orderBy={orderBy}
                        availableOrderByOptions={availableOrderByOptions}
                        setOrderBy={setOrderBy}
                        intl={intl}
                    />
                </div>

                <div className='col-12 col-lg-3 col-xl-1'>
                    <label htmlFor='clear' className='form-label'>
                        {/* <Translation id="clear_the_filters" defaultMessage="Empty" /> */}
                        &nbsp;
                    </label>
                    <Button
                        icon='trash'
                        secondary
                        onClick={onReset}
                    >
                        <Translation id='clear' defaultMessage='Clear' />
                    </Button>
                </div>
            </div>
        </form>
    )
}

const SaleItemsOrderBy = (
    {
        availableOrderByOptions,
        orderBy,
        setOrderBy,
        intl
    }
) => {
    return <React.Fragment>
        <label htmlFor='sortBy' className='form-label'>
            <Translation id='sortBy' defaultMessage='Sort by' />
        </label>
        <Select
            options={availableOrderByOptions}
            value={orderBy}
            onChange={(selectedOption) => setOrderBy(selectedOption)}
            placeholder={
                intl.formatMessage({
                    id: 'select'
                }) + '...'
            }
        />
    </React.Fragment>
}


const SaleItemsFilters = (
    {
        orderBy,
        filters,
        intl,
        expansionOptions,
        rarityOptions,
        productsCategoriesOptions,
        availableLangs,
        availableStatus,
        booleanOptions,
        availableOrderByOptions,
        priceListFilterOptions,
        shouldDisplayProductCategoryFilter,
        shouldDisplayPricesListFilter,
        setFilters,
        setOrderBy,
        onSubmit,
        onReset
    }
) => {
    const [showFilterModal, setShowFilterModal] = useState(false)

    return <div>
        <div className='d-none d-lg-block'>
            <Filters
                filters={filters}
                orderBy={orderBy}
                intl={intl}
                expansionOptions={expansionOptions}
                rarityOptions={rarityOptions}
                productsCategoriesOptions={productsCategoriesOptions}
                availableLangs={availableLangs}
                availableStatus={availableStatus}
                booleanOptions={booleanOptions}
                availableOrderByOptions={availableOrderByOptions}
                priceListFilterOptions={priceListFilterOptions}
                shouldDisplayProductCategoryFilter={shouldDisplayProductCategoryFilter}
                shouldDisplayPricesListFilter={shouldDisplayPricesListFilter}
                setFilters={setFilters}
                setOrderBy={setOrderBy}
                onSubmit={onSubmit}
                onReset={onReset}
            />
        </div>

        <div className='d-lg-none row'>
            <div className='col-8'>
                <Filter
                    type='text'
                    label={intl.formatMessage({ id: 'name' })}
                    name='search'
                    value={filters.search}
                    updateFilterValue={setFilters}
                    placeholder={intl.formatMessage({ id: 'SEARCH_BY_NAME_OR_WHATEVER' })}
                />
            </div>

            <div className='col-4'>
                <Button onClick={() => setShowFilterModal(true)} >
                    <span className='material-icons-outlined' style={{ fontSize: '32px' }}>
                        filter_alt
                    </span>
                </Button>
            </div>

            {showFilterModal && (
                <Modal title={<Translation id='filters' defaultMessage='Filters' />}
                       onClose={() => setShowFilterModal(false)}>
                    <Filters
                        filters={filters}
                        orderBy={orderBy}
                        intl={intl}
                        expansionOptions={expansionOptions}
                        rarityOptions={rarityOptions}
                        productsCategoriesOptions={productsCategoriesOptions}
                        availableLangs={availableLangs}
                        availableStatus={availableStatus}
                        booleanOptions={booleanOptions}
                        availableOrderByOptions={availableOrderByOptions}
                        priceListFilterOptions={priceListFilterOptions}
                        shouldDisplayProductCategoryFilter={shouldDisplayProductCategoryFilter}
                        shouldDisplayPricesListFilter={shouldDisplayPricesListFilter}
                        setFilters={setFilters}
                        setOrderBy={setOrderBy}
                        onSubmit={onSubmit}
                        onReset={onReset}
                    />
                </Modal>
            )}

            <div className='col'>
                <SaleItemsOrderBy
                    orderBy={orderBy}
                    availableOrderByOptions={availableOrderByOptions}
                    setOrderBy={setOrderBy}
                    intl={intl}
                />
            </div>
        </div>
    </div>
}

export default injectIntl(SaleItemsFilters)
