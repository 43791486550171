import { PriceListDto } from '../../store/prices-list/core/dto/price-list.dto'

export class WantToSellUrlService {

    public static queryParamFilterByPriceList(priceList: PriceListDto): string {
        const queryParams = new URLSearchParams()
        const filters = {
            priceList: { value: priceList.id, label: priceList.name },
        }
        queryParams.set('filters', JSON.stringify(filters))

        return  '?' + queryParams.toString();
    }

}