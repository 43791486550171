import { PriceListContainerReduxProps } from './PriceListContainer'
import React, { useEffect, useState } from 'react'
import Button from '../../components/global/Button'
import Translation from '../../components/global/Translation'
import PricesListCreate from './PricesListCreate'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../index'
import { fetchMyPricesList } from '../../store/prices-list/actions'
import LocalizedLink from '../../components/global/LocalizedLink'
import { WantToSellUrlService } from '../WantToSell/WantToSellUrlService'

interface Props extends PriceListContainerReduxProps {
}

const PricesList: React.FC<Props> = ({ pricesList }) => {
    const [showCreateModal, setShowCreateModal] = useState(false)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(fetchMyPricesList())
    }, [])

    return (

        <div>
            <div className='container-fluid'>
                <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='col-12 col-md-4'>
                        <h2><Translation id='prices_list_title' defaultMessage='Prices list' /></h2>
                    </div>
                    {showCreateModal && <PricesListCreate
                        onClose={() => setShowCreateModal(false)}
                    />}
                    <div className='col-12 col-md-3 col-lg-2'>
                        <Button icon='plus-circle' onClick={() => setShowCreateModal(true)}>
                            <Translation id='NEW' defaultMessage='New' />
                        </Button>
                    </div>
                </div>
            </div>


            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap' }}>
                {pricesList.map(price =>
                    <LocalizedLink
                        routeId='ROUTE_USER_DASHBOARD'
                        params={{ view: 'sale', id: '' }}
                        queryString={WantToSellUrlService.queryParamFilterByPriceList(price)}
                    >
                        <div className='container-fluid' style={{ maxWidth: '300px', height: '125px' }} key={price.id}>
                            {price.name}
                        </div>
                    </LocalizedLink>
                )}
            </div>
        </div>
    )


}

export default PricesList