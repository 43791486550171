import React from 'react'
import Button from '../../components/global/Button'
import Translation from '../../components/global/Translation'
import Modal from '../../components/global/Modal'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { PriceListCreateDto } from '../../store/prices-list/core/dto/price-list.dto'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createPriceList } from '../../store/prices-list/actions'
import { getPricesListFormErrors } from '../../store/prices-list/reducers'
import { AppDispatch } from '../../index'
import FormikInput from '../../components/shared/form/FormikInput'

interface Props {
    onClose: () => void
}

const PricesListCreate: React.FC<Props> = ({ onClose }) => {
    const intl = useIntl()
    const dispatch = useDispatch<AppDispatch>()

    const errors = useSelector(getPricesListFormErrors)


    const initialValues: PriceListCreateDto = {
        name: ''
    }

    const onSubmit = (values: PriceListCreateDto, action) => {
        dispatch(createPriceList(values))
            .then(() => onClose())
            .catch(() => {

            })
    }

    const validationSchema = Yup.object({
        name: Yup.string()
            .required(intl.formatMessage({ id: 'REQUIRED' }))
            .min(3, intl.formatMessage({ id: 'MIN_LENGTH' }, { length: 3 }))
    })

    const formik = useFormik<PriceListCreateDto>({
        initialValues,
        onSubmit,
        validationSchema
    })

    const handleClickSubmit = () => {
        formik.submitForm()
    }

    return <Modal
        title={<Translation id='PRICES_LIST_CREATE' defaultMessage='Create a price list'></Translation>}
        onClose={onClose}
    >
        <FormikProvider
            value={formik}>
            <form className='form-inline'>
                <div className='row form-inline'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <FormikInput
                            name='name'
                            label={intl.formatMessage({ id: 'NAME' })}
                            required={true}
                        />

                        {/*<label htmlFor="name" style={{ width: '240px' }}>*/}

                        {/*</label>*/}

                        {/*<div style={{display: 'flex', flexDirection: 'column'}}>*/}
                        {/*    <input*/}
                        {/*        required*/}
                        {/*        id="name"*/}
                        {/*        name="name"*/}
                        {/*        autoComplete="off"*/}
                        {/*        value={formik.values.name}*/}
                        {/*        onChange={formik.handleChange}*/}
                        {/*        onBlur={formik.handleBlur}*/}
                        {/*    />*/}
                        {/*    {formik.touched.name && formik.errors.name ? <div style={{ color: 'red' }}>{formik.errors.name}</div> : null}*/}
                        {/*</div>*/}

                    </div>

                </div>

                <div className='row'>
                    <div className='col'>
                        <Button
                            className='bt mt-2 mr-2 ml-2'
                            type='submit'
                            disabled={!formik.dirty || !formik.isValid}
                            onClick={handleClickSubmit}>
                            {intl.formatMessage({ id: 'CREATE' })}
                        </Button>
                    </div>
                </div>
            </form>
        </FormikProvider>
    </Modal>
}

export default PricesListCreate